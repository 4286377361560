<template>
    <div class="login-container">
        <div class="the-login w-100">
            <div
                class="the-login__container rounded-lg d-flex flex-column m-auto"
            >
                <img
                    class="the-login__logo"
                    src="@/assets/Xplora-logo-black.png"
                    alt="Xplora Logo"
                />
                <app-form
                    form-class="the-login__form"
                    :on-submit="onSubmit"
                    :needs-confirmation="false"
                    :show-title-divider="false"
                >
                    <template v-slot:fields>
                        <app-input
                            id="the-login__password"
                            :label="$t('password')"
                            type="password"
                            name="password"
                            :placeholder="$t('password')"
                            :is-valid="isValid"
                            :required="true"
                            :invalid-feedback="$t('errors.DoesNotMatch')"
                            @update-value="(e) => (password = e)"
                        ></app-input>

                        <app-input
                            id="the-login__confirmPassword"
                            :label="$t('confirmPassword')"
                            type="password"
                            name="password"
                            :placeholder="$t('confirmPassword')"
                            :is-valid="isValid"
                            :required="true"
                            :invalid-feedback="$t('errors.DoesNotMatch')"
                            @update-value="(e) => (confirmPassword = e)"
                        ></app-input>
                    </template>

                    <template v-slot:buttons>
                        <b-button
                            type="submit"
                            variant="primary"
                            class="w-100 the-login__button"
                            >{{ $t('forms.buttons.login') }}</b-button
                        >
                    </template>
                </app-form>
            </div>
        </div>
    </div>
</template>

<script>
    import AppInput from '../components/ui/AppInput.vue';
    import AppForm from '../components/ui/AppForm.vue';

    export default {
        components: { AppInput, AppForm },
        name: 'ResetPassword',
        data() {
            return {
                password: '',
                confirmPassword: '',
                isValid: null,
            };
        },
        methods: {
            async onSubmit() {
                if (this.confirmPassword !== this.password) {
                    this.feedback = this.$t('errors.DoesNotMatch');
                    this.isValid = false;
                    this.showOverlay = false;
                } else {
                    try {
                        this.isValid = null;
                        const { token } = this.$route.params;
                        console.log(token, this.password, this.confirmPassword);
                        // we need to add endpoint here
                        // this.$router.push('/');
                    } catch (e) {
                        this.isValid = false;
                    }
                }
            },
        },
    };
</script>
